<template>
  <b-card
    class="tw-mb-0"
    no-body
  >
    <b-card-header class="tw-flex tw-flex-col align-items-baseline">
      <p class="tw-text-lg tw-font-bold">
        Sales Forecast
      </p>
      <p class="tw-text-md tw-text-gray-500">
        Based on your most recent stats
      </p>
    </b-card-header>
    <b-card-body>
      <SpinnerLoader v-if="isLoading" />
      <div
        v-else
      >
        <template

          v-if="isDataAvailable"
        >
          <div class="tw-flex tw-flex-row tw-gap-1 tw-uppercase justify-content-center">
            <feather-icon
              icon="CalendarIcon"
              size="14"
            />
            <div class="tw-font-bold">
              Today
            </div>
          </div>
          <div
            class="tw-grid tw-grid-cols-2"
          >
            <div class="tw-flex tw-flex-col tw-text-center">
              <div class="tw-text-xl">
                {{ quantity.display }}
              </div>
              <div class="tw-text-center tw-uppercase tw-text-gray-500">
                Units
              </div>
            </div>
            <div class="tw-flex tw-flex-col tw-text-center">
              <h2 class="tw-text-xl">
                {{ revenue.display }}
              </h2>
              <div class="text-muted text-uppercase">
                Revenue
              </div>
            </div>
          </div>
        </template>
        <div
          v-else
          class="tw-text-center"
        >
          {{ errorMessage || 'No data available' }}
        </div>
      </div>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'

export default {
  name: 'SalesForecast',
  components: {
    SpinnerLoader,
    BCard,
    BCardHeader,
    BCardBody,
  },
  data() {
    return {
      quantity: undefined,
      revenue: undefined,
      isLoading: false,
      errorMessage: undefined,
    }
  },
  computed: {
    isDataAvailable() {
      return this.quantity && this.revenue
    },
  },
  mounted() {
    this.loadData()
    setInterval(() => {
      this.loadData(false)
    }, 300000)
  },
  methods: {
    loadData(loading = true) {
      this.isLoading = loading
      this.$store.dispatch('dashboard/fetchSalesForecast').then(result => {
        if (result.message) {
          this.errorMessage = result.message
        } else {
          this.revenue = result.revenue
          this.quantity = result.quantity
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
