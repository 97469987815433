<template>
  <svg>
    <rect
      :x="0"
      :y="0"
      :width="18"
      :height="18"
      :fill="color"
      :opacity="0"
    />
    <line
      v-if="dashStyle"
      stroke-dasharray="7, 4"
      x1="0"
      y1="9"
      x2="18"
      y2="9"
      :stroke="color"
      stroke-width="3"
    />
    <line
      v-else
      x1="0"
      y1="9"
      x2="18"
      y2="9"
      :stroke="color"
      stroke-width="3"
    />
  </svg>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'Noisy Signal',
    },
  },
  data() {
    return {
      color: this.item.series.isVisible() ? this.item.marker.fill : '#888',
      dashStyle: this.item.series.name.includes('Compare'),
    }
  },
}
</script>
