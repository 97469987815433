<template>
  <b-col
    class="subscription-card"
    sm="6"
    lg="4"
  >
    <b-card
      no-body
      class="text-center"
    >
      <b-card-header class="justify-content-center pb-1">
        <b-card-title
          class="d-flex flex-column align-items-center mb-25"
        >
          <div
            class="title skeleton"
            style="height: 16.8px; width: 180px;"
          />
          <div
            class="price skeleton"
            style="height: 34px; width: 80px;"
          />
          <div
            class="unit skeleton mt-50"
            style="height: 14px; width: 80px;"
          />
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div
          class="d-flex flex-column align-items-center p-2"
        >
          <div
            class="price skeleton"
            style="height: 14px; width: 220px;"
          />
          <div
            class="price skeleton mt-1"
            style="height: 14px; width: 220px;"
          />
          <div
            class="price skeleton mt-50"
            style="height: 14px; width: 180px;"
          />
          <div
            class="price skeleton mt-1"
            style="height: 14px; width: 220px;"
          />
          <div
            class="price skeleton mt-1"
            style="height: 14px; width: 170px;"
          />
          <div
            class="price skeleton mt-1"
            style="height: 14px; width: 160px;"
          />
          <div
            class="price skeleton mt-50"
            style="height: 14px; width: 150px;"
          />
        </div>
        <div class="d-flex flex-column align-items-center">
          <div
            class="price skeleton mt-1"
            style="height: 38px; width: 114px;"
          />
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'SubscriptionSkeleton',
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCol,
  },
}
</script>

<style lang="scss" scoped>
  .subscription-card {
    .card-header {
      background: linear-gradient(118deg,#7367f0,rgba(115,103,240,.7));
      .card-title {
        div {
          color: #ffffff;
          &.title {
            font-size: 1rem;
          }
          &.price {
            font-size: 2rem;
            margin-top: 1rem;
          }
          &.unit {
            font-size: 0.85rem;
          }
        }
      }
    }
  }

  .skeleton {
    opacity: 0.8;
    border-radius: 3px;
    background-color: #e2e5e7;
    background-image:
        linear-gradient(
                90deg,
                rgba(#fff, 0),
                rgba(#fff, 0.5),
                rgba(#fff, 0)
        );
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
  }

  @keyframes shine {
    to {
      background-position: right -40px top 0;
    }
  }
</style>
