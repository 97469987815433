<template>
  <b-col
    v-if="!isLoading && data"
    class="subscription-card"
    sm="6"
    lg="4"
  >
    <b-card
      no-body
      class="text-center"
    >
      <b-card-header class="justify-content-center pb-1">
        <b-card-title
          v-if="!isLoading"
          class="mb-25"
        >
          <div class="tw-flex tw-flex-col tw-gap-4">
            <div class="tw-text-xl">
              {{ title }}
            </div>
            <div class="price">
              ${{ price }}
            </div>
            <div class="unit">
              {{ unit }}
            </div>
            <div class="tw-text-sm">
              The offered plan fits your account based on last month sales
            </div>
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="!isLoading"
          class="description p-2"
          v-html="description"
        />
        <!--eslint-enable-->
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :disabled="isSubscribeLoading"
            @click="onSubscribe"
          >
            <div v-if="isSubscribeLoading">
              <b-spinner
                class="mr-50"
                small
                label="Small Spinner"
                type="grow"
              />
              <b-spinner
                class="mr-50"
                small
                label="Small Spinner"
                type="grow"
              />
              <b-spinner
                small
                label="Small Spinner"
                type="grow"
              />
            </div>
            <div v-else>
              Subscribe
            </div>
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
  <SubscriptionSkeleton v-else />
</template>

<script>
import {
  BButton,
  BCard, BCardBody, BCardHeader, BCardTitle, BCol, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SubscriptionSkeleton from '@/views/dashboard/widgets/components/shop-synchronization/SubscriptionSkeleton.vue'
import EventBus from '@/utils/event-bus'

export default {
  name: 'Subscription',
  directives: {
    Ripple,
  },
  components: {
    SubscriptionSkeleton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCol,
    BButton,
    BSpinner,
  },
  data() {
    return {
      debouncedAction: undefined,
      isLoading: true,
      isSubscribeLoading: false,
      data: undefined,
    }
  },
  computed: {
    title() {
      return this.data.title ? this.data.title : null
    },
    price() {
      return this.data.price ? this.data.price.amount : null
    },
    unit() {
      return this.data.unit ? this.data.unit : null
    },
    description() {
      return this.data.description ? this.data.description : null
    },
  },
  created() {
    EventBus.$on('ws_subscription_created', this.onSubscribed)
  },
  destroyed() {
    EventBus.$off('ws_subscription_updated', this.onSubscribed)
  },
  mounted() {
    this.$store.dispatch('subscription/fetchAvailableSubscription').then(result => {
      this.data = result
    }).finally(() => {
      this.isLoading = false
    })
  },
  methods: {
    onSubscribe() {
      if (this.debouncedAction) clearTimeout(this.debouncedAction)
      this.debouncedAction = setTimeout(() => {
        this.isSubscribeLoading = true
        this.$store.dispatch('subscription/subscribe', { price_id: this.data.id }).then(result => {
          const width = 700
          const height = 750
          const left = (window.screen.availWidth / 2) - (width / 2)
          const top = (window.screen.availHeight / 2) - (height / 2)
          const features = `location=yes,height=${height},width=${width},scrollbars=yes,status=yes,left=${left},top=${top}screenX=${left},screenY=${top}`
          if (this.connectWindow) this.connectWindow.close()
          this.connectWindow = window.open(result, '_blank', features)
        }).finally(() => {
          this.isSubscribeLoading = false
        })
      }, 500)
    },
    onSubscribed() {
      if (this.connectWindow) this.connectWindow.close()
    },
  },
}
</script>

<style lang="scss" scoped>
  .subscription-card {
    .card-header {
      background: linear-gradient(118deg,#7367f0,rgba(115,103,240,.7));
      .card-title {
        div {
          color: #ffffff;
          &.title {
            font-size: 1rem;
          }
          &.price {
            font-size: 2rem;
          }
          &.unit {
            font-size: 0.85rem;
          }
        }
      }
    }
  }
</style>
