import { render, staticRenderFns } from "./Margin.vue?vue&type=template&id=6f67c048&scoped=true&"
import script from "./Margin.vue?vue&type=script&lang=js&"
export * from "./Margin.vue?vue&type=script&lang=js&"
import style0 from "./Margin.vue?vue&type=style&index=0&id=6f67c048&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f67c048",
  null
  
)

export default component.exports