<template>
  <b-card
    class="tw-mb-0"
    no-body
  >
    <b-card-header class="tw-flex tw-flex-col align-items-baseline">
      <div class="tw-flex tw-flex-row tw-justify-between tw-w-full tw-text-lg tw-font-bold">
        <div>Margin</div>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="cursor-pointer"
          @click.stop="onSettingsClick"
        />
      </div>
      <div class="tw-flex tw-flex-row tw-gap-1 tw-items-baseline tw-text-md tw-text-gray-500">
        <div>What's my profit margin?</div>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="tw-flex tw-flex-col justify-content-center">
        <div
          v-if="isSettingsVisible"
          class="widget-settings"
        >
          <div class="d-flex flex-column">
            <div class="d-flex flex-row justify-content-end align-items-center mb-50">
              <div class="mr-50">
                Date Period
              </div>
              <DatePeriod
                v-model="selectedPeriod"
                :value="selectedPeriod"
                :all-ranges="false"
                @update="onDatePeriodUpdate"
              />
            </div>
            <div class="tw-flex tw-flex-col mb-50">
              <div class="tw-flex tw-flex-row tw-justify-end tw-items-center">
                <div class="mr-50">
                  Calculate Based On
                </div>
                <DxSelectBox
                  :value="calculateBaseOn"
                  :items="calculateBaseOnOptions"
                  display-expr="title"
                  value-expr="id"
                  @value-changed="onCalculateBaseOnUpdate"
                />
              </div>
              <div
                v-if="isCalculateBaseOnProfit"
                class="tw-text-xs tw-text-right text-warning"
              >
                For proper calculations you need to add cost for all products
              </div>
            </div>
            <div class="d-flex flex-row justify-content-end align-items-center flex-gap p-1" />
            <div class="d-flex flex-row justify-content-end">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                size="sm"
                @click.stop="onSettingsClick"
              >
                Close
              </b-button>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-row tw-gap-1 tw-uppercase justify-content-center">
          <feather-icon
            icon="PieChartIcon"
            size="20"
          />
        </div>
        <div class="tw-text-xl tw-text-center tw-font-bold text-success">
          {{ marginDisplay }}
        </div>
        <div class="tw-text-center">
          <router-link :to="{ name: 'sales', params: { timeFilter: selectedPeriod } }">
            {{ periodLabel }}
          </router-link>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import { DxSelectBox } from 'devextreme-vue'
import Ripple from 'vue-ripple-directive'
import DatePeriod from '@/components/formElements/DatePeriod.vue'

export default {
  name: 'Margin',
  components: {
    BButton,
    DxSelectBox,
    DatePeriod,
    BCard,
    BCardHeader,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: 'margin-widget-1',
      margin: null,
      isLoading: false,
      isSettingsVisible: false,
      periodLabel: null,
      selectedPeriod: null,
      calculateBaseOn: null,
      calculateBaseOnOptions: [
        {
          id: 'profit',
          title: 'Profit',
        },
        {
          id: 'net',
          title: 'Net',
        },
      ],
    }
  },
  computed: {
    marginDisplay() {
      if (this.margin) {
        return this.margin.display
      }
      return '--'
    },
    isCalculateBaseOnProfit() {
      return this.calculateBaseOn === 'profit'
    },
  },
  mounted() {
    this.loadData()
    setInterval(() => {
      this.loadData(false)
    }, 300000)
  },
  methods: {
    loadData(loading = true) {
      this.isLoading = loading
      this.$store.dispatch('dashboard/fetchMargin', {
        date_range: this.selectedPeriod,
        calculate_base_on: this.calculateBaseOn,
      }).then(result => {
        this.margin = result.margin
        this.periodLabel = result?.period?.text ?? 'Last 30 Days'
        this.selectedPeriod = result?.period?.alias ?? 'last30days'
        this.calculateBaseOn = result?.settings?.calculate_base_on ?? 'profit'
      }).finally(() => {
        this.isLoading = false
      })
    },
    onSettingsClick() {
      this.isSettingsVisible = !this.isSettingsVisible
    },
    onCalculateBaseOnUpdate(e) {
      this.calculateBaseOn = e.value
      this.saveSettings().then(() => {
        this.loadData()
      })
    },
    onDatePeriodUpdate() {
      this.isSettingsVisible = false
      this.saveSettings().then(() => {
        this.loadData()
      })
    },
    saveSettings() {
      return this.$store.dispatch('user/updateSettings', {
        index: this.id,
        payload: {
          date_range: this.selectedPeriod,
          calculate_base_on: this.calculateBaseOn,
        },
      }).then(response => {
        this.$bvToast.toast(response.message, {
          title: 'User Settings',
          variant: 'success',
          solid: true,
        })
      }).catch(() => {
        this.$bvToast.toast('Server error, please try again later.', {
          title: 'Oops!',
          variant: 'danger',
          solid: true,
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .f-size-25 {
    font-size: 25px;
  }

  .f-size-18 {
    font-size: 18px;
  }

  .v-align-middle {
    vertical-align: middle
  }

  .widget-settings {
    background: #283046;
    position: absolute;
    width: 380px;
    right: 5px;
    top: 75px;
    z-index: 10;
    padding: 1rem;
    border: 1px solid #3b4253;
  }
</style>
