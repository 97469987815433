<template>
  <b-col>
    <b-card no-body>
      <b-card-header class="justify-content-center pb-1">
        <b-card-title class="mb-25 tw-border-b-2">
          <h5>{{ titleText }}</h5>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div
          v-if="isConnected"
          class="tw-text-center tw-p-2"
        >
          Your Amazon store is being synchronized with all historical data. Full historical synchronization can take  6+ hours. (Stores with long sales history may take several days) The dashboard for your store will be unlocked once the last 60 days of data are synced. After initial reports are pulled, estimation for synchronization will be shown below.
        </div>
        <div
          v-else
          class="tw-text-center tw-p-2"
        >
          To start Synchronization, please select the marketplace and click on the connect image.
        </div>
        <div class="steps-wrapper">
          <div
            class="step"
            :class="{ active: !isConnected, complete: isConnected }"
          >
            <div class="step-counter">
              <AmazonIcon :size="18" />
            </div>
            <div class="step-name">
              Connected
            </div>
          </div>
          <div
            class="step"
            :class="{ active: isConnected, complete: isSynchronized }"
          >
            <div class="step-counter">
              <RefreshIcon :size="18" />
            </div>
            <div class="step-name">
              Sync in Progress
            </div>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center mt-2">
          <ShopConnection v-if="!isConnected" />
          <SynchronizationDetails v-else />
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCol,
} from 'bootstrap-vue'
import AmazonIcon from '@/components/icons/AmazonIcon.vue'
import RefreshIcon from '@/components/icons/RefreshIcon.vue'
import ShopConnection from '@/components/ShopConnection.vue'
import SynchronizationDetails from '@/views/dashboard/widgets/components/shop-synchronization/SynchronizationDetails.vue'

export default {
  name: 'ShopSynchronization',
  components: {
    SynchronizationDetails,
    ShopConnection,
    RefreshIcon,
    AmazonIcon,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCol,
  },
  computed: {
    isConnected() {
      return this.$store.getters['auth/isStoreConnected']
    },
    isSynchronized() {
      return this.$store.getters['auth/isSynchronized']
    },
    titleText() {
      return this.isConnected ? 'Synchronization in Progress' : 'Connect Your Amazon Seller Store'
    },
  },
}
</script>

<style lang="scss" scoped>
  .steps-wrapper {
    display: flex;
    justify-content: space-between;

    .step {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #ebe9f1;
        margin-bottom: 6px;
      }

      .step-name {
        text-transform: capitalize;
      }

      &:not(:first-child) {
        &:before {
          position: absolute;
          content: "";
          //border-bottom: 2px solid #3b4253;
          border-bottom: 2px solid #ebe9f1;
          width: 100%;
          top: 20px;
          left: -50%;
          z-index: 2;
        }
      }

      &.active {
        .step-counter {
          background-color: #7366f0;
          color: #fff;
        }
        .step-counter::v-deep path {
          fill: #fff;
        }
      }

      &.complete {
        .step-counter {
          background-color: #28c76f;
          color: #fff;
        }
        .step-counter::v-deep path {
          fill: #fff;
        }
      }
    }
  }

  .dark-layout {
    .steps-wrapper {
      .step {
        .step-counter {
          background-color: #283046;
          border: 1px solid #3b4253;
        }

        &:not(:first-child) {
          &:before {
            border-bottom: 2px solid var(--primary);
          }
        }

        &.active {
          .step-counter {
            background-color: var(--primary);
            color: #fff;
          }

          .step-counter::v-deep path {
            fill: #fff;
          }
        }

        &.complete {
          .step-counter {
            background-color: #28c76f;
            color: #fff;
          }
          .step-counter::v-deep path {
            fill: #fff;
          }
        }
      }
    }
  }
</style>
