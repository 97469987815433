<template>
  <b-card
    class="tw-mb-0"
    no-body
  >
    <b-card-header class="tw-flex tw-flex-col align-items-baseline">
      <div class="tw-flex tw-flex-row tw-justify-between tw-w-full tw-text-lg tw-font-bold">
        <div>Sales</div>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="cursor-pointer"
          @click.stop="onSettingsClick"
        />
      </div>
      <div
        v-if="!isLoading"
        class="tw-flex tw-flex-row tw-gap-1 tw-items-baseline tw-text-md tw-text-gray-500"
      >
        <router-link :to="{ name: 'sales', params: { timeFilter: selectedPeriod } }">
          {{ periodLabel }}
        </router-link>
      </div>
    </b-card-header>
    <b-card-body class="p-0">
      <SpinnerLoader
        v-if="isLoading"
      />
      <div v-else>
        <div
          v-if="isSettingsVisible"
          class="widget-settings"
        >
          <div class="d-flex flex-column">
            <div class="d-flex flex-row justify-content-end align-items-center mb-50">
              <div class="mr-50">
                Date Period
              </div>
              <DatePeriod
                v-model="selectedPeriod"
                :value="selectedPeriod"
                :all-ranges="false"
                @update="onDatePeriodUpdate"
              />
            </div>
            <div class="d-flex flex-row justify-content-end align-items-center mb-50">
              <div class="mr-50">
                Sort By
              </div>
              <DxSelectBox
                :value="sortBy"
                :items="sortOptions"
                display-expr="title"
                value-expr="id"
                @value-changed="onSortByUpdate"
              />
            </div>
            <div class="d-flex flex-row justify-content-end align-items-center flex-gap p-1" />
            <div class="d-flex flex-row justify-content-end">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                size="sm"
                @click.stop="onSettingsClick"
              >
                Close
              </b-button>
            </div>
          </div>
        </div>
        <div
          v-if="isDataAvailable"
          class="tw-flex tw-flex-col tw-gap-2 tw-pb-5"
        >
          <div
            v-for="(item, index) in dataSource"
            :key="`top-seller-${index}`"
            class="tw-flex tw-flex-row tw-items-baseline"
          >
            <div
              class="tw-w-1/3 tw-p-1 tw-text-center"
            >
              <a
                :href="item.amazon_link"
                target="_blank"
              >
                <b-avatar
                  rounded
                  :size="currentBreakPoint === 'lg' ? '45px' : '35px'"
                  :src="item.image"
                >
                  <feather-icon
                    v-if="isEmpty(item.image)"
                    size="18"
                    icon="ImageIcon"
                  />
                </b-avatar>
              </a>
            </div>
            <div
              class="tw-w-1/3"
            >
              <a
                :href="item.amazon_link"
                target="_blank"
                class="tw-cursor-pointer"
              >{{ item.asin }}</a>
            </div>
            <div class="tw-w-1/3">
              <div class="tw-text-xl text-success tw-font-bold">
                {{ getProductValue(item) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-5 tw-h-full"
        >
          <div class="text-center">
            No Sales
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BAvatar, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { DxSelectBox } from 'devextreme-vue'
import DatePeriod from '@/components/formElements/DatePeriod.vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'

export default {
  name: 'TopSellers',
  components: {
    DxSelectBox,
    SpinnerLoader,
    BButton,
    DatePeriod,
    BCard,
    BCardHeader,
    BCardBody,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: 'top-sellers',
      periodLabel: null,
      selectedPeriod: null,
      dataSource: [],
      isLoading: false,
      isSettingsVisible: false,
      sortBy: 'revenue',
      sortOptions: [
        {
          id: 'revenue',
          title: 'Revenue',
        },
        {
          id: 'profit',
          title: 'Profit',
        },
        {
          id: 'net',
          title: 'Net',
        },
        {
          id: 'quantity',
          title: 'Units Sold',
        },
      ],
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isDataAvailable() {
      return this.dataSource.length > 0
    },
  },
  mounted() {
    this.loadData()
    setInterval(() => {
      this.loadData(false)
    }, 300000)
  },
  methods: {
    loadData(loading = true) {
      this.isLoading = loading
      this.$store.dispatch('dashboard/fetchTopSellers', {
        date_range: this.selectedPeriod,
        sort_by: this.sortBy,
      }).then(result => {
        this.dataSource = result?.items ?? []
        this.periodLabel = result?.period?.text ?? 'Last 30 Days'
        this.selectedPeriod = result?.period?.alias ?? 'last30days'
        this.sortBy = result?.settings?.sort_by ?? 'revenue'
      }).finally(() => {
        this.isLoading = false
      })
    },
    isEmpty(value) {
      return value === undefined || value === null || value.length === 0
    },
    onSettingsClick() {
      this.isSettingsVisible = !this.isSettingsVisible
    },
    onSortByUpdate(e) {
      this.sortBy = e.value
      this.saveSettings().then(() => {
        this.loadData()
      })
    },
    onDatePeriodUpdate() {
      this.isSettingsVisible = false
      this.saveSettings().then(() => {
        this.loadData()
      })
    },
    saveSettings() {
      return this.$store.dispatch('user/updateSettings', {
        index: this.id,
        payload: {
          date_range: this.selectedPeriod,
          sort_by: this.sortBy,
        },
      }).then(response => {
        this.$bvToast.toast(response.message, {
          title: 'User Settings',
          variant: 'success',
          solid: true,
        })
      }).catch(() => {
        this.$bvToast.toast('Server error, please try again later.', {
          title: 'Oops!',
          variant: 'danger',
          solid: true,
        })
      })
    },
    getProductValue(item) {
      switch (this.sortBy) {
        case 'revenue':
          return item.revenue.display
        case 'profit':
          return item.profit.display
        case 'net':
          return item.net.display
        case 'quantity':
          return item.quantity.display
        default:
          return item.revenue.display
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .f-size-25 {
    font-size: 25px;
  }

  .f-size-18 {
    font-size: 18px;
  }

  .v-align-middle {
    vertical-align: middle
  }

  .seller-item {
    padding-top: 1rem;

    .badge {
      top: 0;
      left: 0;
      padding: 0.3rem;
      font-size: 14px;
      min-width: 25px;
      color: #fff;
      border-radius: 0 0 5px 0;
      background-color: #7367f0;
    }
  }
</style>
<style lang="scss">
  .seller-item {
    border-top: 1px solid #ededed;
  }

  .dark-layout {
    .seller-item {
      border-top: 1px solid #161d31;
    }
  }

  .widget-settings {
    background: #283046;
    position: absolute;
    width: 320px;
    right: 5px;
    top: 75px;
    z-index: 10;
    padding: 1rem;
    border: 1px solid #3b4253;
  }
</style>
