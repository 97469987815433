<template>
  <b-card
    class="tw-mb-0"
    no-body
  >
    <b-card-header class="tw-flex tw-flex-col">
      <div class="tw-flex tw-flex-row tw-justify-between tw-w-full tw-text-lg tw-font-bold">
        <div>Sales</div>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="cursor-pointer"
          @click.stop="onSettingsClick"
        />
      </div>
      <div class="tw-flex tw-flex-row tw-gap-1 tw-items-baseline tw-w-full tw-text-md tw-text-gray-500">
        <feather-icon
          icon="TrendingUpIcon"
          size="14"
        />
        <div>{{ periodLabel }}</div>
      </div>
    </b-card-header>
    <b-card-body class="tw-p-2">
      <SpinnerLoader
        v-if="isLoading"
      />
      <div v-else>
        <div
          v-if="isSettingsVisible"
          class="widget-settings"
        >
          <div class="d-flex flex-column">
            <div class="d-flex flex-row justify-content-end align-items-center mb-50">
              <div class="mr-50">
                Date Period
              </div>
              <DatePeriod
                v-model="selectedPeriod"
                :value="selectedPeriod"
                @update="onDatePeriodUpdate"
              />
            </div>
            <div class="d-flex flex-row justify-content-end align-items-center flex-gap p-1">
              <b-form-checkbox
                v-model="showNet"
                name="test_mode"
                @change="onSettingsChanged"
              >Net
              </b-form-checkbox>
              <b-form-checkbox
                v-model="showProfit"
                name="test_mode"
                @change="onSettingsChanged"
              >Profit
              </b-form-checkbox>
            </div>
            <div class="d-flex flex-row justify-content-end">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                size="sm"
                @click.stop="onSettingsClick"
              >
                Close
              </b-button>
            </div>
          </div>
        </div>
        <div class="tw-grid tw-grid-cols-2">
          <div class="tw-flex tw-flex-col tw-text-center">
            <div class="tw-text-xl">
              {{ totalQuantity }}
            </div>
            <div class="tw-text-center tw-uppercase tw-text-gray-500">
              Units
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-text-center">
            <div class="tw-text-xl">
              {{ totalRevenue }}
            </div>
            <div class="tw-text-center tw-uppercase tw-text-gray-500">
              Revenue
            </div>
          </div>
        </div>
        <div
          class="tw-grid"
          :class="{'tw-grid-cols-2': showNet && showProfit, 'tw-grid-cols-1': !showNet || !showProfit}"
        >
          <div
            v-if="showNet"
            class="tw-flex tw-flex-col tw-text-center"
          >
            <div class="tw-text-xl">
              {{ totalNet }}
            </div>
            <div class="tw-text-center tw-uppercase tw-text-gray-500">
              Net
            </div>
          </div>
          <div
            v-if="showProfit"
            class="tw-flex tw-flex-col tw-text-center"
          >
            <div class="tw-text-xl">
              {{ totalProfit }}
            </div>
            <div class="tw-text-center tw-uppercase tw-text-gray-500">
              Profit
            </div>
          </div>
        </div>
        <hr class="mb-2">
        <div v-if="isDataAvailable">
          <div
            class="apply-job-package sxr-light-primary rounded"
            style="padding-top: 0.3rem; padding-bottom: 0.3rem"
          >
            <div class="tw-text-center tw-uppercase tw-font-bold">
              Top Sellers:
            </div>
          </div>
          <div class="tw-flex tw-flex-col justify-content-center tw-mt-5">
            <div
              v-for="item in data"
              :key="item.asin"
              class="tw-flex tw-flex-row tw-justify-center tw-mb-2 tw-items-baseline"
            >
              <div class="tw-text-right tw-w-1/3 tw-text-base lg:tw-text-2xl tw-pr-1">
                <span class="Units Sold">{{ item.quantity.display }}</span>
              </div>
              <div class="lg:tw-visible tw-p-1">
                X
              </div>
              <div class="tw-text-center">
                <ProductImage
                  :size="currentBreakPoint === 'lg' ? '45px' : '35px'"
                  :src="item.image"
                  :title="item.title"
                  icon-size="18"
                  :is-zoom-available="true"
                  attr-class="custom-padding"
                  :asin="item.asin"
                  :product-url="item.amazon_link"
                  :marketplace="item.marketplace.marketplace_id"
                  :marketplace-flag="item.marketplace.country_code"
                />
              </div>
              <div class="lg:tw-visible tw-p-1">
                =
              </div>
              <div class="tw-w-1/3 tw-text-base lg:tw-text-2xl tw-text-left tw-font-bold text-success tw-pl-1">
                <span title="Profit">{{ item.profit.display }}</span>
              </div>
            </div>
            <div class="tw-text-center">
              <router-link :to="{ name: 'sales', params: { timeFilter: selectedPeriod}}">
                See All
              </router-link>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-center"
        >
          No Sales
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DatePeriod from '@/components/formElements/DatePeriod.vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import ProductImage from '@/components/ProductImage.vue'

export default {
  name: 'Sales',
  components: {
    ProductImage,
    SpinnerLoader,
    BFormCheckbox,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    DatePeriod,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    datePeriod: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      periodLabel: null,
      selectedPeriod: null,
      showProfit: true,
      showNet: true,
      isSettingsVisible: false,
      totals: {},
      data: [],
      isLoading: true,
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    isDataAvailable() {
      return this.data.length > 0
    },
    totalProfit() {
      return this.totals?.profit?.display ?? 0
    },
    totalNet() {
      return this.totals?.net?.display ?? 0
    },
    totalRevenue() {
      return this.totals?.revenue?.display ?? 0
    },
    totalQuantity() {
      return this.totals?.quantity?.display ?? 0
    },
  },
  mounted() {
    this.selectedPeriod = this.datePeriod
    this.loadData()
    setInterval(() => {
      this.loadData(false)
    }, 300000)
  },
  methods: {
    isEmpty(value) {
      return value === undefined || value === null || value.length === 0
    },
    loadData(loading = true) {
      this.isLoading = loading
      this.$store.dispatch('dashboard/fetchSales', { date_range: this.selectedPeriod, widget_id: this.id }).then(result => {
        if (result.totals) {
          this.totals = result.totals
          this.data = result.data
          this.periodLabel = result.period.text
          this.selectedPeriod = result.period.alias
          this.showNet = result.settings.show_net
          this.showProfit = result.settings.show_profit
        }
      }).catch(() => {
        // console.error(e)
      }).finally(() => {
        this.isLoading = false
      })
    },
    onSettingsClick() {
      this.isSettingsVisible = !this.isSettingsVisible
    },
    onRefreshClick() {
      this.loadData()
    },
    onSettingsChanged() {
      this.$store.dispatch('user/updateSettings', {
        index: this.id,
        payload: {
          date_range: this.selectedPeriod,
          show_profit: this.showProfit,
          show_net: this.showNet,
        },
      }).then(response => {
        this.$bvToast.toast(response.message, {
          title: 'User Settings',
          variant: 'success',
          solid: true,
        })
      }).catch(() => {
        this.$bvToast.toast('Server error, please try again later.', {
          title: 'Oops!',
          variant: 'danger',
          solid: true,
        })
      })
    },
    onDatePeriodUpdate() {
      this.isSettingsVisible = false
      this.loadData()
      this.$store.dispatch('user/updateSettings', {
        index: this.id,
        payload: {
          date_range: this.selectedPeriod,
        },
      }).then(response => {
        this.$bvToast.toast(response.message, {
          title: 'User Settings',
          variant: 'success',
          solid: true,
        })
      }).catch(() => {
        this.$bvToast.toast('Server error, please try again later.', {
          title: 'Oops!',
          variant: 'danger',
          solid: true,
        })
      })
    },
  },
}
</script>

<style lang="scss">
  .custom-padding {
    padding: 0.61rem;
  }
  .sxr-light-primary {
    background: rgb(0 165 68 / 30%);
    color: var(--primary);
  }
  .dark-layout {
    .sxr-light-primary {
      background: rgb(0 165 68 / 12%);
      color: var(--primary);
    }
  }
</style>
<style lang="scss" scoped>
  .f-size-25 {
    font-size: 25px;
  }

  .f-size-18 {
    font-size: 18px;
  }

  .v-align-middle {
    vertical-align: middle
  }

  .widget-settings {
    background: #283046;
    position: absolute;
    width: 320px;
    right: 5px;
    top: 75px;
    z-index: 10;
    padding: 1rem;
    border: 1px solid #3b4253;
  }
</style>
