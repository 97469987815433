<template>
  <b-card
    class="tw-mb-0"
    no-body
  >
    <b-card-header class="tw-flex tw-flex-col align-items-baseline">
      <p class="tw-text-lg tw-font-bold">
        Record
      </p>
      <div class="tw-flex tw-flex-row tw-gap-1 tw-items-baseline tw-text-md tw-text-gray-500">
        <feather-icon
          icon="StarIcon"
          size="14"
        />
        <div>Profit In 1 Day</div>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="tw-flex tw-flex-col justify-content-center">
        <div class="tw-flex tw-flex-row tw-gap-1 tw-uppercase justify-content-center">
          <feather-icon
            icon="CalendarIcon"
            size="14"
          />
          <div class="tw-font-bold">
            <router-link :to="{ name: 'sales', query: { startDate: dateAsQuery, endDate: dateAsQuery}}">
              {{ formattedDate }}
            </router-link>
          </div>
        </div>
        <div class="tw-text-xl tw-text-center tw-font-bold text-success">
          {{ profitDisplay }}
        </div>
        <div class="tw-text-center">
          Profit Made
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  name: 'RecordProfit',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
  },
  data() {
    return {
      date: null,
      profit: null,
    }
  },
  computed: {
    profitDisplay() {
      if (this.profit && this.profit.display) {
        return this.profit.display
      }
      return '--'
    },
    formattedDate() {
      if (this.date) {
        return moment(this.date).format('MMMM DD, YYYY')
      }
      return '--/--'
    },
    dateAsQuery() {
      if (this.date) {
        return moment(this.date).format('YYYY-MM-DD')
      }
      return null
    },
  },
  mounted() {
    this.loadData()
    setInterval(() => {
      this.loadData(false)
    }, 300000)
  },
  methods: {
    loadData(loading = true) {
      this.isLoading = loading
      this.$store.dispatch('dashboard/fetchRecordProfit').then(result => {
        this.date = result.date
        this.profit = result.profit
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .f-size-25 {
    font-size: 25px;
  }

  .f-size-18 {
    font-size: 18px;
  }

  .v-align-middle {
    vertical-align: middle
  }
</style>
