<template>
  <div v-if="!isLoading">

    <div
      v-for="(item, index) in items"
      :key="`task-${index}`"
      class="tw-flex tw-flex-row tw-gap-2 tw-items-baseline"
      :class="{'text-success': item.status === 'done', 'text-danger': item.status === 'failed', 'text-warning': !['done', 'failed'].includes(item.status)}"
    >
      <FeatherIcon
        v-if="item.status === 'done'"
        icon="CheckIcon"
      />
      <FeatherIcon
        v-else-if="item.status === 'failed'"
        icon="AlertOctagonIcon"
      />
      <RefreshIcon
        v-else
        class="rotate"
        :size="12"
      />
      {{ item.message }} {{ renderMeta(item) }}
    </div>
    <p v-if="dashboardWillBeAvailableIn">
      <strong>Dashboard is estimated to be unlocked in {{ dashboardWillBeAvailableIn }}</strong>
    </p>
    <p>
      <strong v-if="approximateTime">Full historical synchronization is estimated to take {{ approximateTime }}</strong>
      <strong v-else>As soon as all reports are generated we will show estimated time for full synchronization</strong>
    </p>
  </div>
  <div v-else>
    <b-spinner
      class="mr-50"
      small
      label="Small Spinner"
      type="grow"
    />
    <b-spinner
      class="mr-50"
      small
      label="Small Spinner"
      type="grow"
    />
    <b-spinner
      small
      label="Small Spinner"
      type="grow"
    />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import RefreshIcon from '@/components/icons/RefreshIcon.vue'
import EventBus from '@/utils/event-bus'

export default {
  name: 'SynchronizationDetails',
  components: {
    RefreshIcon,
    BSpinner,
  },
  data() {
    return {
      isLoading: true,
      items: [],
      approximateTime: undefined,
      dashboardWillBeAvailableIn: undefined,
      refreshInterval: undefined,
    }
  },
  mounted() {
    this.loadData()
    this.refreshInterval = setInterval(() => {
      this.loadData()
    }, 1000 * 60 * 5)
  },
  created() {
    EventBus.$on('ws_synchronization_finished', this.onSynchronizationFinished)
    EventBus.$on('ws_synchronization_refresh', this.loadData)
  },
  destroyed() {
    EventBus.$off('ws_synchronization_finished')
    EventBus.$off('ws_synchronization_refresh')
  },
  methods: {
    renderMeta(data) {
      if (data.meta) {
        switch (data.group) {
          case 'products':
            return `( ${(this.numberFormat(data.meta.total_loaded_products))} / ${this.numberFormat(data.meta.total_products)} )`
          case 'orders':
            return `( ${this.numberFormat(data.meta.total_loaded_orders)} / ${this.numberFormat(data.meta.total_orders)} )`
          default:
            return ''
        }
      }
      return ''
    },
    numberFormat(number) {
      return new Intl.NumberFormat().format(number)
    },
    loadData() {
      this.$store.dispatch('user/getShopSyncStatus').then(response => {
        this.items = response.items
        this.approximateTime = response.approximateTime
        this.dashboardWillBeAvailableIn = response.dashboard_will_be_available_in || undefined
        if (response.isSynchronized) {
          clearInterval(this.refreshInterval)
          this.$store.commit('auth/synchronized')
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    onSynchronizationFinished() {
      clearInterval(this.refreshInterval)
      this.$store.commit('auth/synchronized')
    },
  },
}
</script>

<style lang="scss" scoped>
  .rotate {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
  }
  @-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }

  .text-primary::v-deep path {
    fill: #7366f0;
  }

  .text-warning::v-deep path {
    fill: #ff9f43;
  }
</style>
