<template>
  <b-card
    class="tw-mb-0"
    no-body
  >
    <b-card-header class="tw-flex tw-flex-col align-items-baseline">
      <p class="tw-text-lg tw-font-bold">
        Stock
      </p>
      <div class="tw-flex tw-flex-row tw-gap-1 tw-items-baseline tw-text-md tw-text-gray-500">
        <feather-icon
          icon="BarChart2Icon"
          size="14"
        />
        <div>Level</div>
      </div>
    </b-card-header>
    <b-card-body class="text-center">
      <SpinnerLoader v-if="isLoading" />
      <div
        v-else
        class="tw-flex tw-flex-col tw-gap-5"
      >
        <div
          v-for="(metric) in scope"
          :key="metric"
          class="tw-flex tw-flex-col tw-gap-1 tw-mb-2"
          :class="getMetricTextColor(metric)"
        >
          <router-link
            style="color: inherit"
            :to="{ name: 'inventory', query: { stock: getMetricKey(metric) } }"
          >
            <div class="tw-uppercase">
              {{ getMetricLabel(metric) }}
            </div>
            <div>
              <b-progress
                :max="100"
                class="progress-bar-danger"
                :variant="getMetricColor(metric)"
                height="2rem"
              >
                <b-progress-bar
                  :value="getMetricProgressValue(metric)"
                >
                  <span class="tw-uppercase tw-pr-1 tw-pl-1"><strong>{{ getMetricValue(metric) }}</strong> {{ getMetricValue(metric) > 1 ? 'Units': 'Unit' }}</span>
                </b-progress-bar>
              </b-progress>
            </div>
            <div
              v-if="getMetricHint(metric) !== null"
              class="tw-flex tw-flex-row tw-gap-2 tw-items-baseline justify-content-center tw-uppercase"
            >
              <div>Will Last</div>
              <div>
                <feather-icon
                  v-if="getMetricHint(metric) !== null"
                  icon="CalendarIcon"
                  size="16"
                />
              </div>
              <div>
                {{ getMetricHint(metric) }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BProgress, BProgressBar,
} from 'bootstrap-vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'

const defaultScope = [
  'high',
  'ideal',
  'low',
  'too_low',
  'out_of_stock',
]

export default {
  name: 'Stock',
  components: {
    BProgress,
    BProgressBar,
    SpinnerLoader,
    BCard,
    BCardHeader,
    BCardBody,
  },
  props: {
    scope: {
      type: Array,
      default: () => defaultScope,
    },
  },
  data() {
    return {
      metricConfig: {
        high: {
          key: 'high',
          color: 'primary',
          label: 'High',
          hint: ' > 6 Month',
          progressValue: 100,
        },
        ideal: {
          key: 'ideal',
          color: 'success',
          label: 'Ideal',
          hint: ' 3-6 Month',
          progressValue: 80,
        },
        low: {
          key: 'low',
          color: 'warning',
          label: 'Low',
          hint: ' 1-2 Month',
          progressValue: 55,
        },
        out_of_stock: {
          key: 'out_of_stock',
          color: 'danger',
          label: 'Out Of Stock',
          hint: null,
          progressValue: 0,
        },
        too_low: {
          key: 'too_low',
          color: 'danger bg-lighten-2',
          label: 'Too Low',
          hint: ' < 1 Month',
          progressValue: 40,
        },
      },
      stock: {},
      isLoading: true,
    }
  },
  mounted() {
    this.loadData()
    setInterval(() => {
      this.loadData(false)
    }, 300000)
  },
  methods: {
    loadData(loading = true) {
      this.isLoading = loading
      this.$store.dispatch('dashboard/fetchInventoryStock').then(result => {
        this.stock = result
      }).finally(() => {
        this.isLoading = false
      })
    },
    getMetricColor(metricName) {
      return this.metricConfig[metricName]?.color || 'primary'
    },
    getMetricProgressValue(metricName) {
      return this.metricConfig[metricName]?.progressValue || 0
    },
    getMetricTextColor(metricName) {
      return `text-${this.metricConfig[metricName]?.color}`
    },
    getMetricBgColor(metricName) {
      return `bg-${this.metricConfig[metricName]?.color}`
    },
    getMetricLabel(metricName) {
      return this.metricConfig[metricName]?.label
    },
    getMetricKey(metricName) {
      return this.metricConfig[metricName]?.key
    },
    getMetricHint(metricName) {
      return this.metricConfig[metricName]?.hint
    },
    getMetricValue(metricName) {
      if (!this.stock[metricName]) {
        return '--'
      }
      return this.stock[metricName].display
    },
    onRefreshClick() {
      this.loadData()
    },
  },
}
</script>

<style scoped>

</style>
