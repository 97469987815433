<template>
  <b-card
    class="tw-mb-0"
    no-body
  >
    <b-card-header class="tw-flex tw-flex-col align-items-baseline">
      <p class="tw-text-lg tw-font-bold">
        Listing By Type
      </p>
    </b-card-header>
    <b-card-body>
      <div
        v-if="isLoading"
        class="d-flex emulated-flex-gap flex-row justify-content-center align-items-center"
      >
        <b-spinner
          small
          label="Small Spinner"
          type="grow"
        />
        <b-spinner
          small
          label="Small Spinner"
          type="grow"
        />
        <b-spinner
          small
          label="Small Spinner"
          type="grow"
        />
      </div>
      <div
        v-else
        class="d-flex flex-column"
      >
        <DxPieChart
          id="pie"
          :data-source="dataSource"
          palette="Bright"
          @point-click="pointClickHandler($event)"
          @legend-click="legendClickHandler($event)"
        >
          <DxSeries
            argument-field="status"
            value-field="count"
          >
            <DxLabel :visible="true">
              <DxConnector
                :visible="true"
                :width="1"
              />
            </DxLabel>
          </DxSeries>
          <DxLegend
            :row-count="2"
            vertical-alignment="top"
            horizontal-alignment="center"
            item-text-position="right"
          />
        </DxPieChart>
        <div class="d-flex flex-column">
          <div class="apply-job-package bg-light-primary rounded mb-1 text-center p-50">
            <h5>Status By Numbers:</h5>
          </div>
          <div
            v-for="(item, index) in dataSource"
            :key="`${item.status}_${index}`"
            class="d-flex flex-row justify-content-between p-40 pb-50"
          >
            <div>{{ item.status }}</div>
            <div>{{ item.count }}</div>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>

import {
  BCard, BCardBody, BCardHeader, BSpinner,
} from 'bootstrap-vue'

import DxPieChart, {
  DxSeries,
  DxLabel,
  DxConnector,
  DxLegend,
} from 'devextreme-vue/pie-chart'

export default {
  name: 'InventoryStatusValue',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    DxPieChart,
    DxSeries,
    DxLabel,
    DxConnector,
    DxLegend,
    BSpinner,
  },
  data() {
    return {
      data: {},
      isLoading: true,
    }
  },
  computed: {
    dataSource() {
      return [
        {
          count: this.data?.active?.amount ?? 0,
          id: 1,
          status: 'Active',
        },
        {
          count: this.data?.inactive?.amount ?? 0,
          id: 2,
          status: 'Inactive',
        },
        {
          count: this.data?.incomplete?.amount ?? 0,
          id: 3,
          status: 'Incomplete',
        },
      ]
    },
  },
  mounted() {
    this.loadData()
    setInterval(() => {
      this.loadData(false)
    }, 300000)
  },
  methods: {
    loadData(loading = true) {
      this.isLoading = loading
      this.$store.dispatch('dashboard/fetchInventoryStatus').then(result => {
        this.data = result
      }).finally(() => {
        this.isLoading = false
      })
    },
    pointClickHandler({ target }) {
      this.$router.push({ name: 'inventory', query: { status: target.data.status.toLowerCase() } })
    },
    legendClickHandler(e) {
      const arg = e.target
      const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0]

      this.toggleVisibility(item)
    },
    toggleVisibility(item) {
      // eslint-disable-next-line no-unused-expressions
      item.isVisible() ? item.hide() : item.show()
    },
  },
}
</script>

<style scoped>

</style>
