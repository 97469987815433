<template>
  <router-link style="color: inherit" :to="{ name: 'sellers' }">
    <b-card
      class="tw-mb-0"
      no-body
    >
      <b-card-header class="tw-flex tw-flex-col align-items-baseline">
        <p class="tw-text-lg tw-font-bold">
          Sellers
        </p>
        <div class="tw-flex tw-flex-row tw-gap-1 tw-items-baseline tw-text-md tw-text-gray-500">
          <div>Any new sellers on my listings?</div>
        </div>
      </b-card-header>
      <b-card-body>
        <SpinnerLoader v-if="isLoading" />
        <div
          v-else
          class="tw-flex tw-flex-col tw-gap-5"
        >
          <div class="tw-flex tw-flex-col tw-gap-2">
            <div class="tw-flex tw-flex-row tw-gap-1 justify-content-center tw-text-center">
              <feather-icon
                icon="CalendarIcon"
                size="14"
              />
              <div>24 Hours</div>
            </div>
            <div
              class="tw-flex tw-flex-col"
            >
              <div class="tw-flex tw-flex-row tw-gap-2">
                <div
                  class="tw-flex tw-flex-col tw-w-1/2 tw-text-right"
                  :class="{
                    'text-danger': dataSource.new_last_24_hours.total > 0,
                    'text-success': dataSource.new_last_24_hours.total <= 0
                  }"
                >
                  <div class="tw-text-2xl tw-font-bold">
                    {{ dataSource.new_last_24_hours.total }}
                  </div>
                  <div>Sellers</div>
                </div>
                <div class="tw-flex tw-flex-col tw-w-1/2">
                  <b-progress
                    value="58"
                    max="100"
                    class="progress-bar-danger"
                    variant="danger"
                    style="max-width: 100px"
                  />
                  <div
                    class="text-danger"
                    style="font-size: 9px;"
                  >
                    {{ dataSource.new_last_24_hours.joined }} joined
                  </div>
                  <b-progress
                    value="52"
                    max="100"
                    class="progress-bar-success"
                    variant="success"
                    style="max-width: 100px"
                  />
                  <div
                    class="text-success"
                    style="font-size: 9px;"
                  >
                    {{ dataSource.new_last_24_hours.left }} left
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-2">
            <div class="tw-flex tw-flex-row tw-gap-1 justify-content-center tw-text-center">
              <feather-icon
                icon="CalendarIcon"
                size="14"
              />
              <div>30 Days</div>
            </div>
            <div
              class="tw-flex tw-flex-col tw-gap-5"
            >
              <div class="tw-flex tw-flex-row tw-gap-2">
                <div
                  class="tw-flex tw-flex-col tw-w-1/2 tw-text-right"
                  :class="{
                    'text-danger': dataSource.new_last_30_days.total > 0,
                    'text-success': dataSource.new_last_30_days.total <= 0
                  }"
                >
                  <div class="tw-text-2xl tw-font-bold">
                    {{ dataSource.new_last_30_days.total }}
                  </div>
                  <div>Sellers</div>
                </div>
                <div class="tw-flex tw-flex-col tw-w-1/2">
                  <b-progress
                    value="58"
                    max="100"
                    class="progress-bar-danger"
                    variant="danger"
                    style="max-width: 100px"
                  />
                  <div
                    class="text-danger"
                    style="font-size: 9px;"
                  >
                    {{ dataSource.new_last_30_days.joined }} joined
                  </div>
                  <b-progress
                    value="52"
                    max="100"
                    class="progress-bar-success"
                    variant="success"
                    style="max-width: 100px"
                  />
                  <div
                    class="text-success"
                    style="font-size: 9px;"
                  >
                    {{ dataSource.new_last_30_days.left }} left
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </router-link>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BProgress,
} from 'bootstrap-vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'

export default {
  name: 'Sellers',
  components: {
    SpinnerLoader,
    BCard,
    BCardHeader,
    BCardBody,
    BProgress,
  },
  data() {
    return {
      dataSource: { new_last_24_hours: { joined: 0, left: 0, total: 0 }, new_last_30_days: { joined: 0, left: 0, total: 0 } },
      isLoading: true,
    }
  },
  mounted() {
    this.loadData()
    setInterval(() => {
      this.loadData(false)
    }, 300000)
  },
  methods: {
    loadData(loading = true) {
      this.isLoading = loading
      this.$store.dispatch('dashboard/fetchSellersActivity').then(result => {
        if (result.new_last_24_hours) {
          this.dataSource = result
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .font-w600 {
    font-weight: 600;
  }
  .font-s35 {
    font-size: 25px;
  }
</style>
