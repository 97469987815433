<template>
  <b-card
    class="tw-mb-0"
    no-body
  >
    <b-card-header class="align-items-baseline">
      <div class="tw-flex tw-flex-col tw-justify-between tw-w-full">
        <div class="tw-flex tw-flex-row tw-justify-between tw-w-full">
          <div class="tw-text-xl tw-font-bold">
            Sales
          </div>
          <feather-icon
            icon="SettingsIcon"
            size="18"
            class="cursor-pointer"
            @click.stop="onSettingsClick"
          />
        </div>
        <div class="tw-flex tw-flex-col md:tw-flex-row lg:tw-flex-row tw-justify-between tw-w-full">
          <div>Number Of Units Sold</div>
          <div v-if="!isLoading">
            Data Shown for <span class="tw-font-bold">{{ periodLabel }}</span>
          </div>
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="d-flex flex-column">
        <div
          v-if="isLoading"
          class="d-flex emulated-flex-gap flex-row justify-content-center align-items-center"
        >
          <b-spinner
            small
            label="Small Spinner"
            type="grow"
          />
          <b-spinner
            small
            label="Small Spinner"
            type="grow"
          />
          <b-spinner
            small
            label="Small Spinner"
            type="grow"
          />
        </div>
        <div
          v-else
          class="d-flex flex-column flex-fill"
        >
          <div
            v-if="isSettingsVisible"
            class="widget-settings"
          >
            <div class="d-flex flex-column">
              <div class="d-flex flex-row justify-content-end align-items-center mb-50">
                <div class="mr-50">
                  Date Period
                </div>
                <DatePeriod
                  v-model="selectedPeriod"
                  :value="selectedPeriod"
                  :all-ranges="false"
                  @update="onDatePeriodUpdate"
                />
              </div>
              <div class="d-flex flex-row justify-content-end align-items-center flex-gap p-1" />
              <div class="d-flex flex-row justify-content-end">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  size="sm"
                  @click.stop="onSettingsClick"
                >
                  Close
                </b-button>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-end mb-50 tw-gap-2 tw-items-baseline">
            <DxCheckBox
              v-model="isLabelShow"
              :value="isLabelShow"
              text="Show Labels"
              @value-changed="saveSettings"
            />
            <aggregation-interval-switch
              v-model="aggregationInterval"
              @value-changed="onAggregationIntervalchanged"
            />
          </div>
          <DxChart
            id="sales_chart"
            class="d-flex flex-column flex-fill m-vh-50"
            :data-source="dataSource"
            :on-legend-click="onLegendClick"
          >
            <DxCommonSeriesSettings
              :ignore-empty-points="false"
              argument-field="date"
              type="line"
            >
              <DxPoint
                hover-mode="allArgumentPoints"
              />
            </DxCommonSeriesSettings>
            <DxValueAxis position="left" />
            <DxValueAxis
              name="aov"
              position="right"
            >
              <DxLabel
                :format="{ style: 'currency', currency: currency, useGrouping: true }"
              />
            </DxValueAxis>
            <DxArgumentAxis
              :key="aggregationInterval"
              :aggregation-interval="aggregationInterval"
              argument-type="date"
              tick-interval="day"
              :type="argumentAxisType"
            />
            <DxCrosshair
              :enabled="true"
              dash-style="dash"
            >
              <DxHorizontalLine :visible="false" />
            </DxCrosshair>
            <DxSeries
              value-field="total_units"
              tag="total_units"
              :name="totalUnitsLegendTitle"
            >
              <DxAggregation
                :enabled="true"
                method="sum"
              />
              <DxLabel
                :visible="isLabelShow"
                :customize-text="numberFormat"
              />
            </DxSeries>
            <DxSeries
              value-field="average_order_value"
              tag="average_order_value"
              axis="aov"
              :name="averageOrderValueLegendTitle"
            >
              <DxAggregation
                :enabled="true"
                method="average"
              />
              <DxPoint
                :size="1"
              />
              <DxLabel
                :visible="isLabelShow"
                :customize-text="customizeText"
              />
            </DxSeries>
            <DxTooltip
              :enabled="true"
              :shared="true"
              content-template="tooltipTemplate"
            >
              <DxFormat
                :precision="1"
                type="largeNumber"
              />
            </DxTooltip>
            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
              marker-template="markerTemplate"
            />
            <template #markerTemplate="{ data }">
              <MarkerTemplate :item="data" />
            </template>
            <template #tooltipTemplate="{ data }">
              <SalesTrendsTooltip
                :info="data"
                :currency="currency"
              />
            </template>
          </DxChart>
        </div>
      </div>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard, BCardBody, BCardHeader, BSpinner,
} from 'bootstrap-vue'
import {
  DxArgumentAxis,
  DxChart,
  DxCommonSeriesSettings,
  DxLabel,
  DxSeries,
  DxTooltip,
  DxValueAxis,
  DxFormat,
  DxLegend,
  DxAggregation,
  DxPoint,
  DxCrosshair,
  DxHorizontalLine,
} from 'devextreme-vue/chart'
import moment from 'moment'
import { DxCheckBox } from 'devextreme-vue/check-box'
import Ripple from 'vue-ripple-directive'
import AggregationIntervalSwitch from '@/components/charts/AggregationIntervalSwitch.vue'
import MarkerTemplate from '@/views/trends/components/MarkerTemplate.vue'
import DatePeriod from '@/components/formElements/DatePeriod.vue'
import SalesTrendsTooltip from '@/views/dashboard/widgets/components/SalesTrendsTooltip.vue'

export default {
  name: 'SalesTrends',
  components: {
    SalesTrendsTooltip,
    BButton,
    DatePeriod,
    MarkerTemplate,
    DxCheckBox,
    AggregationIntervalSwitch,
    BCard,
    BCardHeader,
    BCardBody,
    BSpinner,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTooltip,
    DxArgumentAxis,
    DxLabel,
    DxFormat,
    DxLegend,
    DxAggregation,
    DxPoint,
    DxCrosshair,
    DxHorizontalLine,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: 'sales-trends',
      total: {},
      aggregationInterval: 'day',
      argumentAxisType: 'discrete',
      isLabelShow: true,
      isLoading: true,
      currency: 'USD',
      dataSource: [],
      datesMapping: {},
      isSettingsVisible: false,
      periodLabel: null,
      selectedPeriod: null,
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    averageOrderValueLegendTitle() {
      return `Average Order Value: ${this.total?.average_order_value?.display ?? 0}`
    },
    totalUnitsLegendTitle() {
      return `Total Units: ${this.total?.total_units?.display ?? 0}`
    },
  },
  mounted() {
    this.loadData()
    setInterval(() => {
      this.loadData(false)
    }, 300000)
  },
  methods: {
    loadData() {
      this.$store.dispatch('dashboard/fetchSalesTrends', {
        date_range: this.selectedPeriod,
        sort_by: this.sortBy,
      }).then(({
        data, total, period, settings,
      }) => {
        this.periodLabel = period?.text ?? 'Last 30 Days'
        this.selectedPeriod = period?.alias ?? 'last30days'
        this.aggregationInterval = settings?.aggregation_interval ?? 'day'
        this.setArgumentAxisType(this.aggregationInterval)
        this.isLabelShow = settings?.show_labels ?? true
        this.dataSource = data?.map(point => {
          const props = { ...point }
          const momentDate = moment(point.date)
          props.date = momentDate.startOf('day').toDate()
          return props
        }) ?? []
        this.total = total ?? {}
        if (this.total && this.total.currency) {
          this.currency = this.total.currency
        }
        this.isLoading = false
      })
    },
    dateFormat(date) {
      return moment(date).format('MMMM DD, YYYY')
    },
    customizeTooltip(pointInfo) {
      const dateEl = document.createElement('span')
      dateEl.textContent = this.dateFormat(pointInfo.argument)
      dateEl.className = 'tw-font-bold tw-text-lg'
      const items = [
        dateEl.outerHTML,
        `Total Units: ${pointInfo.point.aggregationInfo.data[0].total_units_display}`,
        `Average Order Value: ${pointInfo.point.aggregationInfo.data[0].average_order_value_display}`,
      ]
      const color = pointInfo.point.getColor()

      items.forEach((item, index) => {
        // get first 10 characters of series name
        if (item.indexOf(pointInfo.seriesName.substring(0, 10)) === 0) {
          const element = document.createElement('span')

          element.textContent = item
          element.style.color = color
          element.className = 'active tw-font-bold tw-text-base'

          items[index] = element.outerHTML
        } else {
          items[index] = `<span class="tw-text-base">${item}</span>`
        }
      })

      return { text: `<div class="">${items.join('\n')}</div>` }
    },
    onLegendClick(e) {
      const series = e.target
      if (series.isVisible()) {
        series.hide()
      } else {
        series.show()
      }
    },
    customizeText(e) {
      return this.currencyFormatter(e.value)
    },
    numberFormat(e) {
      return new Intl.NumberFormat('en-US').format(e.value)
    },
    currencyFormatter(value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.currency }).format(value)
    },
    setArgumentAxisType(value) {
      if (value === 'day') {
        this.argumentAxisType = 'discrete'
      } else {
        this.argumentAxisType = undefined
      }
    },
    onAggregationIntervalchanged(value) {
      this.saveSettings()
      this.setArgumentAxisType(value)
    },
    onSettingsClick() {
      this.isSettingsVisible = !this.isSettingsVisible
    },
    onDatePeriodUpdate() {
      this.isSettingsVisible = false
      this.saveSettings().then(() => {
        this.loadData()
      })
    },

    saveSettings() {
      return this.$store.dispatch('user/updateSettings', {
        index: this.id,
        payload: {
          date_range: this.selectedPeriod,
          aggregation_interval: this.aggregationInterval,
          show_labels: this.isLabelShow,
        },
      }).then(response => {
        this.$bvToast.toast(response.message, {
          title: 'User Settings',
          variant: 'success',
          solid: true,
        })
      }).catch(() => {
        this.$bvToast.toast('Server error, please try again later.', {
          title: 'Oops!',
          variant: 'danger',
          solid: true,
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .f-size-25 {
    font-size: 25px;
  }

  .f-size-18 {
    font-size: 18px;
  }

  .v-align-middle {
    vertical-align: middle
  }
</style>
<style lang="scss">
.seller-item {
  border-top: 1px solid #ededed;
}

.dark-layout {
  .seller-item {
    border-top: 1px solid #161d31;
  }
}

.widget-settings {
  background: #283046;
  position: absolute;
  width: 320px;
  right: 5px;
  top: 75px;
  z-index: 10;
  padding: 1rem;
  border: 1px solid #3b4253;
}
</style>
