<template>
  <div
    v-if="!isCurrentBreakPointXs"
    id="dashboard tw-flex tw-flex-col"
    style="padding-bottom: 0.5rem"
  >
    <div
      v-if="isConnected && isSynchronized && isSubscribed"
      class="tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-3 md:tw-grid-cols-3 tw-mb-5"
    >
      <SalesForecast v-if="isCurrentBreakPointXs" />
      <Sales id="sales-widget-1" />
      <Sales id="sales-widget-2" />
      <Sales id="sales-widget-3" />
    </div>
    <div
      v-if="isConnected && isSynchronized && isSubscribed"
      class="tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-4 md:tw-grid-cols-2 tw-mb-5"
    >
      <SalesForecast v-if="!isCurrentBreakPointXs" />
      <RecordSold />
      <RecordProfit />
      <Margin />
    </div>
    <div
      v-if="isConnected && isSynchronized && isSubscribed"
      class="tw-grid tw-grid-cols-1 tw-mb-5"
    >
      <SalesTrends />
    </div>
    <div
      v-if="isConnected && isSynchronized && isSubscribed"
      class="tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-4 md:tw-grid-cols-2 tw-mb-5"
    >
      <Stock />
      <TopSellers />
      <InventoryStatusValue />
      <Sellers />
    </div>
    <b-row v-else-if="!isConnected || !isSynchronized">
      <ShopSynchronization />
    </b-row>
    <b-row
      v-else
      align-h="center"
    >
      <Subscription />
    </b-row>
  </div>
  <div
    v-else
    id="dashboard"
  >
    <div
      v-if="isConnected && isSynchronized && isSubscribed"
      class="tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-3 md:tw-grid-cols-3 tw-mb-5"
    >
      <SalesForecast v-if="isCurrentBreakPointXs" />
      <Sales id="sales-widget-1" />
      <Sales id="sales-widget-2" />
      <Sales id="sales-widget-3" />
    </div>
    <div
      v-if="isConnected && isSynchronized && isSubscribed"
      class="tw-grid tw-grid-cols-1"
    >
      <SalesTrends />
    </div>
    <div
      v-if="isConnected && isSynchronized && isSubscribed"
      class="tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-4 md:tw-grid-cols-2 tw-mb-5 tw-mt-5"
    >
      <RecordSold />
      <RecordProfit />
      <Margin />
    </div>
    <div
      v-if="isConnected && isSynchronized && isSubscribed"
      class="tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-4 md:tw-grid-cols-2 tw-mb-5"
    >
      <Stock />
      <TopSellers />
      <InventoryStatusValue />
      <Sellers class="tw-mb-5" />
    </div>
    <b-row v-else-if="!isConnected || !isSynchronized">
      <ShopSynchronization />
    </b-row>
    <b-row
      v-else
      align-h="center"
    >
      <Subscription />
    </b-row>
  </div>
</template>

<script>
import { BRow } from 'bootstrap-vue'
import Sales from '@/views/dashboard/widgets/Sales.vue'
import RecordSold from '@/views/dashboard/widgets/RecordSold.vue'
import RecordProfit from '@/views/dashboard/widgets/RecordProfit.vue'
import Margin from '@/views/dashboard/widgets/Margin.vue'
import Stock from '@/views/dashboard/widgets/Stock.vue'
import InventoryStatusValue from '@/views/dashboard/widgets/InventoryStatusValue.vue'
import Sellers from '@/views/dashboard/widgets/Sellers.vue'
import TopSellers from '@/views/dashboard/widgets/TopSellers.vue'
import SalesForecast from '@/views/dashboard/widgets/SalesForecast.vue'
import ShopSynchronization from '@/views/dashboard/widgets/ShopSynchronization.vue'
import Subscription from '@/views/dashboard/widgets/Subscription.vue'
import SalesTrends from '@/views/dashboard/widgets/SalesTrends.vue'

export default {
  name: 'Dashboard',
  components: {
    Subscription,
    ShopSynchronization,
    TopSellers,
    InventoryStatusValue,
    RecordSold,
    RecordProfit,
    Sales,
    BRow,
    Margin,
    Stock,
    Sellers,
    SalesForecast,
    SalesTrends,
    // PpcSpend,
  },
  computed: {
    isConnected() {
      return this.$store.getters['auth/isStoreConnected']
    },
    isSynchronized() {
      return this.$store.getters['auth/isSynchronized']
    },
    isSubscribed() {
      return this.$store.getters['auth/isSubscribed']
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
  },
}
</script>

<style lang="scss" scoped>
#dashboard {
  .card {
    .card-title {
      font-size: 1.15rem !important;
    }
  }
}
</style>
